<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-4.png"
    ></v-img>

    <div
      class="page-title text-center px-4 justify-center align-center"
    >
      <h2
        class="font-weight-semibold d-flex align-center justify-center"
        :class="$vuetify.breakpoint.mdAndDown ? 'text-xl': 'text-3xl'"
      >
        <v-icon
          class="me-2"
          color="warning"
          dir="auto"
        >
          {{ icons.mdiAlert }}
        </v-icon>
        <span
          class="me-5"
        >
          {{ $t('page_not_found') }}
        </span>
      </h2>

      <div class="misc-character d-flex justify-center align-center">
        <v-img
          width="100%"
          max-width="700"
          src="@/assets/images/3d-characters/error.png"
        ></v-img>
      </div>

      <v-btn
        color="primary"
        :to="{name: 'manage-events'}"
        class="mb-4 justify-center align-center"
      >
        {{ $t('back_to_home') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/misc.scss';
::v-deep .page-title {
  margin-top: 10%;
}
@media (max-width: 600px) {
  #misc {
    .page-title {
      margin-top: 50%;
    }
  }
}
</style>
